.resume-container {
    code {
        white-space: pre-wrap;
      }
      
      span {
        &.smallcaps {
          font-variant: small-caps;
        }
      
        &.underline {
          text-decoration: underline;
        }
      }
      
      div.column {
        display: inline-block;
        vertical-align: top;
        width: 50%;
      }
      
      em {
        /* color:#224E94; */
        font-style:normal;
        font-weight: bold;
   
    }
      article {
          display: block;
      }
      aside {
          display: block;
      }
      details {
          display: block;
      }
      figcaption {
          display: block;
      }
      figure {
          display: block;
          margin: 0;
      }
      footer {
          display: block;
      }
      header {
          display: block;
      }
      hgroup {
          display: block;
      }
      main {
          display: block;
      }
      nav {
          display: block;
      }
      section {
          display: block;
      }
      summary {
          display: block;
      }
      audio {
          display: inline-block;
          &:not([controls]) {
              display: none;
              height: 0;
          }
      }
      canvas {
          display: inline-block;
      }
      video {
          display: inline-block;
      }
      [hidden] {
          display: none;
      }
      template {
          display: none;
      }
      html {
          font-family: 'Times New Roman', Times, serif;
          input[type="button"] {
              -webkit-appearance: button;
              cursor: pointer;
          }
          input[disabled] {
              cursor: default;
          }
      }
      body {
          margin: 0;
      }
      a {
          background: transparent;
          &:focus {
              outline: thin dotted;
          }
          &:active {
              outline: 0;
          }
          &:hover {
              outline: 0;
          }
      }
      h1,h2,h3,h4,h5,h6{
          margin:30px 0 2px;
          padding:0;font-weight:bold;
          -webkit-font-smoothing:subpixel-antialiased;
          cursor:text
        }
        
        h1{
            font-size:28px;
            color:#000;
            text-align:center
        }
        h2{
            font-size:18px; 
            border-bottom:1px solid #ccc;
            color:#224E94
        }
        h3{
            font-size:18px;
            color:#333
        }
        h4{
            font-size:16px;
            color:#333;
            text-align:center;
            -webkit-margin-before:0.02em;
            -webkit-margin-after:0.02em;
        }
        h5{
            font-size:14px;
            color:#333;
            text-align:center
        }
        
        h6{
            color:#777;
            font-size:14px;
            text-align:center;
            -webkit-margin-before:0.05em
        }
      
        ul{
         font-size: 80%;
      }

      p {
          font-size: 80%
      }

      b {
          font-weight: bold;
      }
      strong {
          font-weight: bold;
      }
      dfn {
          font-style: italic;
      }
      hr {
          -moz-box-sizing: content-box;
          box-sizing: content-box;
          height: 0;
      }
      mark {
          background: #ff0;
          color: #000;
      }
      code {
          font-family: monospace, serif;
          font-size: 1em;
      }
      kbd {
          font-family: monospace, serif;
          font-size: 1em;
      }
      pre {
          font-family: monospace, serif;
          font-size: 1em;
          white-space: pre-wrap;
      }
      samp {
          font-family: monospace, serif;
          font-size: 1em;
      }
      q {
          quotes: "\201C" "\201D" "\2018" "\2019";
      }
      small {
          font-size: 80%;
      }
      sub {
          font-size: 75%;
          line-height: 0;
          position: relative;
          vertical-align: baseline;
          bottom: -0.25em;
      }
      sup {
          font-size: 75%;
          line-height: 0;
          position: relative;
          vertical-align: baseline;
          top: -0.5em;
      }
      img {
          border: 0;
      }
      svg {
          &:not(:root) {
              overflow: hidden;
          }
      }
      fieldset {
          border: 1px solid #c0c0c0;
          margin: 0 2px;
          padding: 0.35em 0.625em 0.75em;
      }
      legend {
          border: 0;
          padding: 0;
      }
      button {
          font-family: inherit;
          font-size: 100%;
          margin: 0;
          line-height: normal;
          text-transform: none;
          -webkit-appearance: button;
          cursor: pointer;
          &::-moz-focus-inner {
              border: 0;
              padding: 0;
          }
      }
      input {
          font-family: inherit;
          font-size: 100%;
          margin: 0;
          line-height: normal;
          &::-moz-focus-inner {
              border: 0;
              padding: 0;
          }
      }
      select {
          font-family: inherit;
          font-size: 100%;
          margin: 0;
          text-transform: none;
      }
      textarea {
          font-family: inherit;
          font-size: 100%;
          margin: 0;
          overflow: auto;
          vertical-align: top;
      }
      input[type="reset"] {
          -webkit-appearance: button;
          cursor: pointer;
      }
      input[type="submit"] {
          -webkit-appearance: button;
          cursor: pointer;
      }
      button[disabled] {
          cursor: default;
      }
      input[type="checkbox"] {
          box-sizing: border-box;
          padding: 0;
      }
      input[type="radio"] {
          box-sizing: border-box;
          padding: 0;
      }
      input[type="search"] {
          -webkit-appearance: textfield;
          -moz-box-sizing: content-box;
          -webkit-box-sizing: content-box;
          box-sizing: content-box;
          &::-webkit-search-cancel-button {
              -webkit-appearance: none;
          }
          &::-webkit-search-decoration {
              -webkit-appearance: none;
          }
      }
      table {
          border-collapse: collapse;
          border-spacing: 0;
      }
      .go-top {
          position: fixed;
          bottom: 2em;
          right: 2em;
          text-decoration: none;
          background-color: #E0E0E0;
          font-size: 12px;
          padding: 1em;
          display: inline;
      }
}
